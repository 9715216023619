import React, { useEffect, useState } from "react";
import { Button, Table, Typography } from "antd";
import { get } from "../../api/axios";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { checkPermission } from "../auth/ProtectedRoutes";

const MessagesTranslations = () => {
    const { Title } = Typography;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const columns = [
        {
            title: " Name",
            dataIndex: "name",
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: " translation en",
            dataIndex: "en",
            sorter: (a, b) => a.en - b.en,
        },
        {
            title: " translation ar",
            dataIndex: "ar",
            sorter: (a, b) => a.ar - b.ar,
        },
    ];

    if (checkPermission("modify-messages-translation")) {
        columns.push({
            title: "Actions",
            dataIndex: "key",
            render: (key) => (
                <div>
                    <Button className="btn btn-outline-warning btn-edit btn-sm p-0 me-3">
                        <Link
                            className="color-inherit text-decoration-none py-1 px-2 d-block"
                            to={`/messages-translations/edit/${key}`}
                        >
                            <EditOutlined />
                        </Link>
                    </Button>
                </div>
            ),
        });
    }

    useEffect(() => {
        get("messages-translations").then((res) => {
            setData(res.data);
            setLoading(false);
        });
    }, []);


    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-6 col-lg-6">
                            <Title level={2} className="text-main mb-4">
                                Messages translation
                            </Title>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid-margin stretch-card wrapper-content">
                <Table
                    locale={{ emptyText: "There are no data" }}
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    size="middle"
                    responsive
                    scroll={{
                        x: 650,
                    }}
                    pagination={false}
                />
            </div>
        </div>
    );
};

export default MessagesTranslations;
