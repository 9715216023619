import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Form,
  Switch,
  notification,
  Select,
} from "antd";
import { get, post } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const AddAgentWhatsappMessage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [inputsValue, setInputsValue] = useState({
    is_active: true,
  });

  const handleSwitch = (status, name) => {
    setInputsValue({ ...inputsValue, [name]: status });
  };



  const onFinish = values => {
    setLoading(true);

    post("agent-whatsapp-messages", {
      ...values,
      ...inputsValue,
    })
      .then(res => {
        notification.success({ message: "Added successfully" });
        setLoading(false);
        navigate("/agent-whatsapp-messages");
      })
      .catch(err => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/agent-whatsapp-messages">
          <ArrowLeftOutlined />
        </Link>
        Agent whatsapp message
      </Title>
      <Form
        className="wrapper-content"
        onFinish={onFinish}
        labelCol={{
          span: 7,
        }}
        wrapperCol={{
          span: 15,
        }}

      >
        <div className="row">
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Message Ar"
              name="message_ar"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Message En"
              name="message_en"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Order "
              name="order"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input type="number" placeholder="Type..." />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item label="Active " name="is_active">
              <Switch
                defaultChecked
                onChange={status => handleSwitch(status, "is_active")}
              />
            </Form.Item>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 offset-lg-4">
            <Form.Item
              wrapperCol={{
                offset: 1,
                span: 16,
              }}
            >
              <Button
                loading={loading}
                size="large"
                block
                type="primary"
                htmlType="submit"
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddAgentWhatsappMessage;
