import React, {  useState } from "react";
import { Input, Typography, Button, Form, Switch, Upload, notification } from "antd";
import {  post, postFromData } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";

import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";

const { Title } = Typography;

const AddSlider = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({
        is_active: true,
    });
   

    const handleSwitch = (status, name) => {
        setInputsValue({ ...inputsValue, [name]: status });
    };

    const handleBeforeUploadAr = (e) => {
        postFromData('/upload-image', { 'image': e, 'store_path': 'sliders' }).then(res => {
            setInputsValue({ ...inputsValue, image_ar: res.data })
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    }
    const handleBeforeUploadEn = (e) => {
        postFromData('/upload-image', { 'image': e, 'store_path': 'sliders' }).then(res => {
            setInputsValue({ ...inputsValue, image_en: res.data })
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    }
    const onFinish = (values) => {
        setLoading(true);

        post("sliders", {
            ...values,
            ...inputsValue,
        })
            .then((res) => {
                notification.success({ message: "Added successfully" });
                setLoading(false);
                navigate("/sliders");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/sliders">
                    <ArrowLeftOutlined />
                </Link>
                Add Slider
            </Title>
            <Form
                className="wrapper-content"
                // layout='vertical'
                onFinish={onFinish}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 15,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <div className="row">
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Title Ar"
                            name="title_ar"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Title En "
                            name="title_en"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Description Ar "
                            name="description_ar"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                             <ReactQuill theme="snow" showCount maxLength={100} />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Description En "
                            name="description_en"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <ReactQuill theme="snow" showCount maxLength={100} />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Order "
                            name="order"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </div>

                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Active " name="is_active">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_active")}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label='Image ar'
                            name='image_ar'
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required!',
                                },
                            ]}
                        >
                            <Upload
                                beforeUpload={handleBeforeUploadAr}
                                listType="picture"
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                            </Upload>
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label='Image en'
                            name='image_en'
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required!',
                                },
                            ]}
                        >
                            <Upload
                                beforeUpload={handleBeforeUploadEn}
                                listType="picture"
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                            </Upload>
                        </Form.Item>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 offset-lg-4">
                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading}
                                size="large"
                                block
                                type="primary"
                                htmlType="submit"
                            >
                                {loading ? "Saving..." : "Save"}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default AddSlider;
