import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Select,
  Form,
  Switch,
  Card,
  notification,
} from "antd";
import { get, post } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

const { Title } = Typography;

const AddVariationCategories = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [form] = Form.useForm();
  const [inputsValue, setInputsValue] = useState({
    is_active: true,
  });

  const handleSwitch = (status, name) => {
    setInputsValue({ ...inputsValue, [name]: status });
  };

  const [dataPackages, setDataPackages] = useState([]);
  const [optionPackages, setOptionPackages] = useState([]);

  useEffect(() => {
    setOptionPackages(
      dataPackages.map(d => ({
        value: d.id,
        label: d.name_en,
      }))
    );
  }, [dataPackages]);

  useEffect(() => {
    get("get-all-packages").then(res => {
      setDataPackages(res.data);
    });
  }, []);

  const onFinish = values => {
    setLoading("loading");

    post("variation-categories", {
      ...values,
      ...inputsValue,
    })
      .then(res => {
        notification.success({ message: "Added successfully" });
        setLoading("");
        navigate("/variation-categories");
      })
      .catch(err => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/variation-categories">
          <ArrowLeftOutlined />
        </Link>
        Add Variation Category
      </Title>
      <Form
        className="wrapper-content"
        onFinish={onFinish}
        form={form}
        labelCol={{
          span: 7,
        }}
        wrapperCol={{
          span: 15,
        }}
        initialValues={{
          is_custom: false,
        }}
      >
        <div className="row">
          <div className="col-lg-6">
            <Form.Item
              label="Name Ar "
              name="name_ar"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>
          <div className="col-lg-6">
            <Form.Item
              label="Name En "
              name="name_en"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>
          <div className="col-lg-6">
            <Form.Item label="Desc Ar " name="description_ar">
              <TextArea placeholder="Type..."></TextArea>
            </Form.Item>
          </div>
          <div className="col-lg-6">
            <Form.Item label="Desc En " name="description_en">
              <TextArea placeholder="Type..."></TextArea>
            </Form.Item>
          </div>
          <div className="col-lg-6">
            <Form.Item
              label="Order"
              name="order"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input type="number" placeholder="Type..." />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Package"
              name="package_ids"
              // rules={[
              //   {
              //     required: true,
              //     message: "This field is required!",
              //   },
              // ]}
            >
              <Select
                mode="multiple"
                showSearch
                optionFilterProp="children"
                className="w-100"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select"
                options={optionPackages}
              />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item label="Active" name="is_active">
              <Switch
                defaultChecked
                onChange={status => handleSwitch(status, "is_active")}
              />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item label="Custom" name="is_custom">
              <Select
                className="w-100"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select"
                options={[
                  {
                    label: "Yes",
                    value: true,
                  },
                  {
                    label: "No",
                    value: false,
                  },
                ]}
                onChange={v => setIsCustom(v)}
              />
            </Form.Item>
          </div>

          {isCustom && (
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <Form.Item label="Message Ar " name="message_ar">
                    <TextArea placeholder="Type..."></TextArea>
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item label="Message En " name="message_en">
                    <TextArea placeholder="Type..."></TextArea>
                  </Form.Item>
                </div>
              </div>
            </div>
          )}
          {/* Item */}

          <div className="col-lg-4 offset-lg-4 mt-4">
            <Form.Item
              wrapperCol={{
                offset: 1,
                span: 16,
              }}
            >
              <Button
                loading={
                  loading === "loading"
                    ? true
                    : loading === "uploading"
                    ? true
                    : false
                }
                size="large"
                block
                type="primary"
                htmlType="submit"
              >
                {loading === "loading"
                  ? "Saving..."
                  : loading === "uploading"
                  ? "Uploading"
                  : "Save"}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddVariationCategories;
