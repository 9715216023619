import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Form,
  Switch,
  notification,
  Select,
  ColorPicker,
  DatePicker,
} from "antd";
import { get, post } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

const { Title } = Typography;

const AddAgentReservation = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [inputsValue, setInputsValue] = useState({
    is_active: true,
  });

  const [dataAgent, setDataAgent] = useState([]);
  const [optionAgent, setOptionAgent] = useState([]);

  useEffect(() => {
    setOptionAgent(
      dataAgent.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    );
  }, [dataAgent]);


  const [dataAgentCustomer, setDataAgentCustomer] = useState([]);
  const [optionAgentCustomer, setOptionAgentCustomer] = useState([]);

  useEffect(() => {
    setOptionAgentCustomer(
      dataAgentCustomer.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    );
  }, [dataAgentCustomer]);





  const [dataProject, setDataProject] = useState([]);
  const [optionProject, setOptionProject] = useState([]);

  useEffect(() => {
    setOptionProject(
      dataProject.map((d) => ({
        value: d.id,
        label: d.name_en,
      }))
    );
  }, [dataProject]);


  const [dataAgentProjectUnit, setDataProjectUnit] = useState([]);
  const [optionProjectUnit, setOptionProjectUnit] = useState([]);

  useEffect(() => {
    setOptionProjectUnit(
      dataAgentProjectUnit.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    );
  }, [dataAgentProjectUnit]);



  useEffect(() => {
    get("get-agent-users").then((res) => {
      setDataAgent(res.data);
    });
    get("get-agent-projects").then((res) => {
      setDataProject(res.data);
    });

  }, []);


  const handleAgentId = value => {
    form.setFieldsValue({
      agent_customer_id: '',
    });
    get(`get-agent-users/${value}/customers`).then(response => {
      setDataAgentCustomer(response.data);
    });

  }

  const handleProjectId = value => {
    form.setFieldsValue({
      agent_project_unit_id: '',
    });
    get(`get-agent-projects/${value}/units`).then(response => {
      setDataProjectUnit(response.data);
    });

  }



  const onFinish = values => {
    setLoading(true);

    post("agent-reservations", {
      ...values,
      ...inputsValue,
      reservation_date: values.reservation_date.format("YYYY-MM-DD"),
      contract_date: values.contract_date.format("YYYY-MM-DD"),
      aqarmap_receiving_commission_date: values.aqarmap_receiving_commission_date.format("YYYY-MM-DD"),
      aqarmap_sent_commission_date: values.aqarmap_sent_commission_date.format("YYYY-MM-DD"),
    })
      .then(res => {
        notification.success({ message: "Added successfully" });
        setLoading(false);
        navigate("/agent-reservations");
      })
      .catch(err => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/agent-reservations">
          <ArrowLeftOutlined />
        </Link>
        Add Agent Reservation
      </Title>
      <Form
        className="wrapper-content"
        form={form}
        layout={"vertical"}
        onFinish={onFinish}
        labelCol={{
          span: 18,
        }}
        wrapperCol={{
          span: 19,
        }}

      >
        <div className="row">
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Agent"
              name="agent_id"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                className="w-100"
                onChange={handleAgentId}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select"
                options={optionAgent}
              />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Agent Customer"
              name="agent_customer_id"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                className="w-100"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select"
                options={optionAgentCustomer}
              />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Agent Project"
              name="agent_project_id"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                className="w-100"
                onChange={handleProjectId}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select"
                options={optionProject}
              />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Agent Project Unit"
              name="agent_project_unit_id"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                className="w-100"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select"
                options={optionProjectUnit}
              />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Initial Price"
              name="initial_price"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Final Price"
              name="final_price"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Commission Rate"
              name="commission_rate"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Total Commission"
              name="total_commission"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Agent Commission"
              name="agent_commission"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Reservation Date"
              name="reservation_date"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <DatePicker placeholder="" className="w-100" />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Contract Date"
              name="contract_date"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <DatePicker placeholder="" className="w-100" />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Aqarmap Receiving Commission Date"
              name="aqarmap_receiving_commission_date"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <DatePicker placeholder="" className="w-100" />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Aqarmap Sent Commission Date"
              name="aqarmap_sent_commission_date"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <DatePicker placeholder="" className="w-100" />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Notes"
              name="notes"
            >
              <TextArea placeholder="Type..." ></TextArea>
            </Form.Item>
          </div>

          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                className="w-100"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select"
                options={[
                  {
                    value: "reserved",
                    label: "Reserved"
                  },
                  {
                    value: "empty",
                    label: "Empty"
                  },
                  {
                    value: "delivered",
                    label: "Delivered"
                  },
                ]}
              />
            </Form.Item>
          </div>

        </div>

        <div className="row">
          <div className="col-lg-4 offset-lg-4">
            <Form.Item
              wrapperCol={{
                offset: 1,
                span: 16,
              }}
            >
              <Button
                loading={loading}
                size="large"
                block
                type="primary"
                htmlType="submit"
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddAgentReservation;
