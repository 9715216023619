import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Spin,
  Form,
  Switch,
  notification,
  Select,
  ColorPicker,
} from "antd";
import { get, put } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const EditAgentDropdown = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [fromLoading, setFromLoading] = useState(false);

  const [inputsValue, setInputsValue] = useState({});

  useEffect(() => {
    get(`agent-dropdowns/${id}`).then(res => {
      setInputsValue(res.data);
      setFromLoading(true);
    });
  }, [id]);

  const [loading, setLoading] = useState(false);

  const handlePicker = (color, name) => {
    setInputsValue({ ...inputsValue, [name]: color.toHexString() })
  };


  const [dataType, setDataType] = useState([]);
  const [optionType, setOptionType] = useState([]);

  useEffect(() => {
    setOptionType(
      dataType.map((d) => ({
        value: d,
        label: d,
      }))
    );
  }, [dataType]);

  useEffect(() => {
    get("get-agent-dropdown-types").then((res) => {
      setDataType(res.data);
    });
  }, []);

  const onFinish = values => {
    setLoading(true);
    const newValues = {
      ...values,
      color: inputsValue.color
    }
    if (newValues.color == null) {
      delete newValues.color
    }
    put(`agent-dropdowns/${id}`, newValues)
      .then(res => {
        notification.success({ message: "Modified successfully" });
        setLoading(false);
        navigate("/agent-dropdowns");
      })
      .catch(err => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/agent-dropdowns">
          <ArrowLeftOutlined />
        </Link>
        Edit Agent Dropdown
      </Title>
      {fromLoading ? (
        <Form
          className="wrapper-content"
          onFinish={onFinish}
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 15,
          }}
          initialValues={{
            name_ar: inputsValue.name_ar,
            name_en: inputsValue.name_en,
            type: inputsValue.type,
            color: inputsValue.color,
            order: inputsValue.order,
            is_active: inputsValue.is_active,
          }}
        >
          <div className="row">
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Name Ar"
                name="name_ar"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Name En "
                name="name_en"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={optionType}
                />
              </Form.Item>
            </div>


            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Order "
                name="order"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input type="number" placeholder="Type..." />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Color"
                name="color"
              >
                <ColorPicker
                  defaultValue="#0277bd"
                  onChange={(color) =>
                    handlePicker(color, 'color')
                  }
                  size="large"
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item label="Active " name="is_active">
                <Switch defaultChecked={inputsValue.is_active} />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? "Updating..." : "Update"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default EditAgentDropdown;
