import React, { useEffect, useState } from "react";
import { Input, Typography, Button, Spin, Form, Switch, Upload, notification, Select } from "antd";
import { get, postFromData, put } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";

const { Title } = Typography;

const EditSliderAgent = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [fromLoading, setFromLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({});
    const [SmallAr, setFileSmallAr] = useState([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
    ]);
    const [SmallEn, setFileSmallEn] = useState([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
    ]);
    const [LargeAr, setFileLargeAr] = useState([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
    ]);
    const [LargeEn, setFileLargeEn] = useState([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
    ]);


    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
    }


    useEffect(() => {
        get(`onboarding-sections/${id}`).then((res) => {
            setInputsValue(res.data.data);
            setFromLoading(true);
            setFileSmallAr(
                [
                    {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: res.data.data.small_image_ar,
                    },
                ]
            )
            setFileSmallEn(
                [
                    {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: res.data.data.small_image_en,
                    },
                ]
            )
            setFileLargeAr(
                [
                    {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: res.data.data.large_image_ar,
                    },
                ]
            )
            setFileLargeEn(
                [
                    {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: res.data.data.large_image_en,
                    },
                ]
            )
        });
    }, [id]);


    const handleBeforeUploadAr = (e) => {
        setLoading('uploading')
        postFromData('/upload-image', { 'image': e, 'store_path': 'onboarding-sections', 'old_path': inputsValue.image_path_ar }).then(res => {
            console.log(res);
            setFileSmallAr([
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: process.env.REACT_APP_BASE_IMAGE_URL + res.data,
                },
            ])
            setInputsValue({ ...inputsValue, image_path_ar: res.data })
            setLoading('');
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    }
    const handleBeforeUploadEn = (e) => {
        setLoading('uploading')
        postFromData('/upload-image', { 'image': e, 'store_path': 'onboarding-sections', 'old_path': inputsValue.image_path_en }).then(res => {
            console.log(res);
            setFileSmallAr([
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: process.env.REACT_APP_BASE_IMAGE_URL + res.data,
                },
            ])
            setInputsValue({ ...inputsValue, image_path_en: res.data })
            setLoading('');
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    }


    const [loading, setLoading] = useState('');

    const handleSwitch = (status, name) => {
        setInputsValue({ ...inputsValue, [name]: status });
    };

    const handleBeforeUploadSmallAr = (e) => {
        postFromData('/upload-image', { 'image': e, 'store_path': 'onboarding-sections', 'old_path': inputsValue.small_image_path_ar }).then(res => {
            setInputsValue({ ...inputsValue, small_image_path_ar: res.data })
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    }
    const handleBeforeUploadSmallEn = (e) => {
        postFromData('/upload-image', { 'image': e, 'store_path': 'onboarding-sections', 'old_path': inputsValue.small_image_path_en }).then(res => {
            setInputsValue({ ...inputsValue, small_image_path_en: res.data })
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    }
    const handleBeforeUploadLargeEn = (e) => {
        postFromData('/upload-image', { 'image': e, 'store_path': 'onboarding-sections' }).then(res => {
            setInputsValue({ ...inputsValue, large_image_en: res.data })
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    }

    const handleBeforeUploadLargeAr = (e) => {
        postFromData('/upload-image', { 'image': e, 'store_path': 'onboarding-sections' }).then(res => {
            setInputsValue({ ...inputsValue, large_image_ar: res.data })
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    }

    const handleType = (e) => {

        setInputsValue({ ...inputsValue, type: e })
    }


    const onFinish = (values) => {
        setLoading('loading');
        put(`onboarding-sections/${id}`, { ...values, 'small_image_ar': inputsValue.small_image_path_ar, 'small_image_en': inputsValue.small_image_path_en, })
            .then((res) => {
                notification.success({ message: "Modified successfully" });
                setLoading('');
                navigate("/sliders-agent");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/sliders-agent">
                    <ArrowLeftOutlined />
                </Link>
                Edit Agent Slider
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        order: inputsValue.order,
                        is_active: inputsValue.is_active,
                        type: inputsValue.type,
                        button_text_en: inputsValue.button_text_en,
                        button_text_ar: inputsValue.button_text_ar,
                        url_en: inputsValue.url_en,
                        url_ar: inputsValue.url_ar,
                    }}
                >
                    <div className="row">
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label='Small image ar'
                                name='small_image_ar'
                            >
                                <Upload
                                    beforeUpload={handleBeforeUploadSmallAr}
                                    listType="picture"
                                    maxCount={1}
                                    defaultFileList={SmallAr}
                                    onPreview={onPreview}
                                >
                                    <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                                </Upload>
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label='Small image en'
                                name='small_image_en'
                            >
                                <Upload
                                    beforeUpload={handleBeforeUploadSmallEn}
                                    listType="picture"
                                    maxCount={1}
                                    defaultFileList={SmallEn}
                                    onPreview={onPreview}
                                >
                                    <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                                </Upload>
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label='Large image ar'
                                name='large_image_ar'
                            >
                                <Upload
                                    beforeUpload={handleBeforeUploadLargeAr}
                                    listType="picture"
                                    maxCount={1}
                                    defaultFileList={LargeAr}
                                    onPreview={onPreview}
                                >
                                    <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                                </Upload>
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label='Large image en'
                                name='large_image_en'
                            >
                                <Upload
                                    beforeUpload={handleBeforeUploadLargeEn}
                                    listType="picture"
                                    maxCount={1}
                                    defaultFileList={LargeEn}
                                    onPreview={onPreview}
                                >
                                    <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                                </Upload>
                            </Form.Item>
                        </div>

                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Order "
                                name="order"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </div>

                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Active " name="is_active">
                                <Switch
                                    defaultChecked
                                    onChange={(status) => handleSwitch(status, "is_active")}
                                />
                            </Form.Item>
                        </div>

                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Type"
                                name="type"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    className="w-100"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    placeholder="Select"
                                    onChange={handleType}
                                    options={[
                                        { value: 'investor', label: "Investor" },
                                        { value: 'agent', label: "Agent" },
                                        { value: 'url', label: "Url" },
                                    ]}
                                />
                            </Form.Item>
                        </div>

                        {
                            inputsValue.type == 'url' && <>
                                {/* Item */}
                                <div className="col-lg-6">
                                    <Form.Item
                                        label="Url ar"
                                        name="url_ar"
                                        rules={[
                                            {
                                                required: true,
                                                message: "This field is required!",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Type..." />
                                    </Form.Item>
                                </div>
                                {/* Item */}
                                <div className="col-lg-6">
                                    <Form.Item
                                        label="Url en"
                                        name="url_en"
                                        rules={[
                                            {
                                                required: true,
                                                message: "This field is required!",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Type..." />
                                    </Form.Item>
                                </div>
                                {/* Item */}
                                <div className="col-lg-6">
                                    <Form.Item
                                        label="Button text ar"
                                        name="button_text_ar"
                                        rules={[
                                            {
                                                required: true,
                                                message: "This field is required!",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Type..." />
                                    </Form.Item>
                                </div>
                                {/* Item */}
                                <div className="col-lg-6">
                                    <Form.Item
                                        label="Button text en"
                                        name="button_text_en"
                                        rules={[
                                            {
                                                required: true,
                                                message: "This field is required!",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Type..." />
                                    </Form.Item>
                                </div>

                            </>
                        }

                    </div>


                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading === 'loading' ? true : loading === 'uploading' ? true : false}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading === 'loading' ? "Updating..." : loading === 'uploading' ? 'Uploading' : "Update"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default EditSliderAgent;