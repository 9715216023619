import React, { useEffect, useState } from "react";
import { Typography, Button, Form, Select, Spin, InputNumber, Input, notification } from "antd";
import { get, put } from "../../api/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const EditVariationValue = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    let { id } = useParams();

    const [fromLoading, setFromLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({});
    const [dataStatistics, setDataStatistics] = useState([]);
    const [dataVariation, setDataVariation] = useState([]);
    const [dataNeighborhoods, setDataNeighborhoods] = useState([]);
    const [dataUnitTypes, setDataUnitTypes] = useState([]);

    const [optionUnitTypes, setOptionUnitTypes] = useState([]);
    const [optionNeighborhoods, setOptionNeighborhoods] = useState([]);

    const [optionVariation, setOptionVariation] = useState([]);

    const [optionStatistics, setOptionStatistics] = useState([]);

    const [selectedCity, setSelectedCity] = useState(null)

    useEffect(() => {
        setOptionVariation(
            dataVariation.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataVariation]);

    useEffect(() => {
        get(`variation-values/${id}`).then((res) => {
            setInputsValue(res.data);
            setSelectedCity(res.data.city_id)
            setFromLoading(true);
        });
        get("variations").then((res) => {
            setDataVariation(res.data.data);
        });
        get("get-all-statistics").then((res) => {
            setDataStatistics(res.data);
        });
        get("get-all-unit-types").then((res) => {
            setDataUnitTypes(res.data);
        });
    }, [id]);

    useEffect(() => {
        setOptionNeighborhoods(
            dataNeighborhoods.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataNeighborhoods]);

    useEffect(() => {
        get(`${selectedCity ?? 1}/get-all-neighborhoods`).then((res) => {
            setDataNeighborhoods(res.data);
        });
    }, [selectedCity]);

    useEffect(() => {
        setOptionStatistics(
            dataStatistics.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataStatistics]);

    useEffect(() => {
        setOptionUnitTypes(
            dataUnitTypes.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataUnitTypes]);

    useEffect(() => {

    }, []);

    const changeSelectedCity = (city) => {
        setSelectedCity(city)
    };
    const onFinish = (values) => {
        setLoading(true);

        put(`variation-values/${id}`, values)
            .then((res) => {
                notification.success({ message: "Modified successfully" });
                setLoading(false);
                // setTimeout(function () {

                // }, 2000);
                navigate("/variation-values");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/variation-values">
                    <ArrowLeftOutlined />
                </Link>
                Edit Variation Value
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        variation_id: inputsValue.variation_id,
                        neighborhood_id: inputsValue.neighborhood_id,
                        statistic_id: inputsValue.statistic_id,
                        unit_type_id: inputsValue.unit_type_id,
                        value: inputsValue.value,
                    }}
                    autoComplete="off"
                >
                    <div className="row">
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label='Variation'
                                name='variation_id'
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    className="w-100"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    placeholder="Select"
                                    onChange={changeSelectedCity}
                                    options={optionVariation}
                                />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label='Neighborhoods'
                                name='neighborhood_id'
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    className="w-100"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    placeholder="Select"
                                    options={optionNeighborhoods}
                                />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label='Statistics'
                                name='statistic_id'
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    className="w-100"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    placeholder="Select"
                                    options={optionStatistics}
                                />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label='Unit Type'
                                name='unit_type_id'
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    className="w-100"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    placeholder="Select"
                                    options={optionUnitTypes}
                                />
                            </Form.Item>
                        </div>
                        {/* ITEM */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Value"
                                name="value"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <InputNumber className="w-100" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading ? "Updating..." : "Update"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : <div className="text-center">
                <Spin size="large" />
            </div>}
        </div>
    );
};

export default EditVariationValue;
