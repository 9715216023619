import React, { useState } from "react";
import { Input, Typography, Button, Form, Upload, Switch, Card, notification } from "antd";
import { post, postFromData } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, CloseOutlined, UploadOutlined } from "@ant-design/icons";

const { Title } = Typography;

const AddInvestQuestion = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [inputsValue, setInputsValue] = useState({
        is_active: true,
        is_multiple_answers: true,
    });


    const handleSwitch = (status, name) => {
        setInputsValue({ ...inputsValue, [name]: status });
    };

    const handleBeforeUpload = (e) => {
        setLoading('uploading')

        postFromData('/upload-image', { 'image': e, 'store_path': 'invest-questions' }).then(res => {
            setInputsValue({ ...inputsValue, icon: res.data })
            setLoading('');
        })
        .catch((err) => {
            notification.error({
                message: "Error!",
                description: err.response.data.message,
            });
            setLoading(false);
        })
    }


    const onFinish = (values) => {
        setLoading('loading');
        values.answers.map((item, index) => {
            if (item.is_active === undefined) {
                item.is_active = false
            }
            if (item.is_soon === undefined) {
                item.is_soon = false
            }
        })

        post("invest-questions",
            {
                ...values,
                ...inputsValue,
            })
            .then((res) => {
                notification.success({message:"Added successfully"});
                setLoading('');
                navigate("/invest-questions");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/invest-questions">
                    <ArrowLeftOutlined />
                </Link>
                Add Invest Question
            </Title>
            <Form
                className="wrapper-content"
                onFinish={onFinish}
                form={form}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 15,
                }}
                initialValues={{
                    answers: [{}],
                }}
            >
                <div className="row">
                    <div className="col-lg-6">
                        <Form.Item
                            label="Question Ar "
                            name="question_ar"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            label="Question En "
                            name="question_en"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            label="Order"
                            name="order"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input type="number" placeholder="Type..." />
                        </Form.Item>
                    </div>

                    <div className="col-lg-6">
                        <Form.Item label="Multiple answers" name="is_multiple_answers">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_multiple_answers")}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item label="Active" name="is_active">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_active")}
                            />
                        </Form.Item>
                    </div>

                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label='Menu Icon'
                            name='icon'
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required!',
                                },
                            ]}
                        >
                            <Upload
                                beforeUpload={handleBeforeUpload}
                                // action={`${baseUrl}upload-image`}
                                // headers={{ "Authorization": `Bearer ${token}` }}
                                listType="picture"
                                maxCount={1}
                            // onChange={handleUpload}
                            >
                                <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                            </Upload>
                        </Form.Item>
                    </div>

                    {/* Item */}
                    <div className="col-lg-12">
                        <Card
                            size="small"
                        >
                            {/* Nest Form.List */}
                            <Form.Item label="Question?">
                                <Form.List name='answers'>
                                    {(subFields, subOpt) => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                rowGap: 16,
                                            }}
                                        >
                                            {subFields.map((subField) => (
                                                <div className="row" key={subField.key}>
                                                    <div className="col-lg-6">
                                                        <Form.Item
                                                            wrapperCol={{
                                                                offset: 1,
                                                                span: 16,
                                                            }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "This field is required!",
                                                                }
                                                            ]}
                                                            label='Answer Ar' name={[subField.name, 'answer_ar']}>
                                                            <Input placeholder="Type..." />
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <Form.Item
                                                            wrapperCol={{
                                                                offset: 1,
                                                                span: 18,
                                                            }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "This field is required!",
                                                                }
                                                            ]}
                                                            label='Answer En' name={[subField.name, 'answer_en']}>
                                                            <Input placeholder="Type..." />
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <Form.Item
                                                            wrapperCol={{
                                                                offset: 2,
                                                                span: 16,
                                                            }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "This field is required!",
                                                                }
                                                            ]}
                                                            label='Order' name={[subField.name, 'order']}>
                                                            <Input className="ms-3" type="number" placeholder="Type..." />
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-lg-5">
                                                        <Form.Item
                                                            wrapperCol={{
                                                                offset: 3,
                                                                span: 16,
                                                            }}
                                                            label='Soon' name={[subField.name, 'is_soon']}>
                                                            <Switch
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-lg-5">
                                                        <Form.Item
                                                            wrapperCol={{
                                                                offset: 3,
                                                                span: 16,
                                                            }}
                                                            label='Active' name={[subField.name, 'is_active']}>
                                                            <Switch
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-lg-1 text-end">
                                                        <CloseOutlined
                                                            className="border"
                                                            onClick={() => {
                                                                subOpt.remove(subField.name);
                                                            }}
                                                        />
                                                    </div>

                                                </div>
                                            ))}
                                            <Button type="dashed" onClick={() => subOpt.add()} block>
                                                + Add Other Answer
                                            </Button>
                                        </div>
                                    )}
                                </Form.List>
                            </Form.Item>
                        </Card>

                        {/* <Form.Item noStyle shouldUpdate>
                            {() => (
                                <Typography>
                                    <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
                                </Typography>
                            )}
                        </Form.Item> */}
                    </div>

                    <div className="col-lg-4 offset-lg-4 mt-4">
                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading === 'loading' ? true : loading === 'uploading' ? true : false}
                                size="large"
                                block
                                type="primary"
                                htmlType="submit"
                            >
                                {loading === 'loading' ? "Saving..." : loading === 'uploading' ? 'Uploading' : "Save"}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default AddInvestQuestion;
