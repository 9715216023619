import { Table } from 'antd';
import React from 'react';

const TableComponent = ({ width = 650, onChangeTable, loading, columns, data, total, rowSelection }) => {
    return (
        <div>
            <div className="w-100">
                <Table
                    onChange={onChangeTable}
                    locale={{ emptyText: "There are no data" }}
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    size="middle"
                    responsive
                    scroll={{
                        x: width,
                    }}
                    pagination={{
                        position: ["bottomCenter"],
                        size: "large",
                        defaultPageSize: 50,
                        total: total,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        pageSizeOptions: ['10', '50', '100', '200', '500'],
                    }}
                    rowKey={record => record.id}
                    rowSelection={{
                        type: "checkbox",
                        ...rowSelection,
                    }}
                />
            </div>
        </div>
    );
}

export default TableComponent;
