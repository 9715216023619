import React, { useEffect, useState } from "react";
import { Input, Typography, Button, Spin, Form, Switch, Upload, notification, Select } from "antd";
import { get, postFromData, put } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";

const { Title } = Typography;

const EditAgentMyAccountMenu = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [fromLoading, setFromLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({});
    const [fileList, setFileList] = useState([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
    ]);

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
    }


    useEffect(() => {
        get(`agent-my-account-menus/${id}`).then((res) => {
            setInputsValue(res.data);
            setFromLoading(true);
            setFileList(
                [
                    {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: res.data.icon,
                    },
                ]
            )
        });
    }, [id]);


    const handleBeforeUpload = (e) => {
        setLoading('uploading')
        postFromData('/upload-image', { 'image': e, 'store_path': 'agent-my-account-menus', 'old_path': inputsValue.icon_path }).then(res => {
            console.log(res);
            setFileList([
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: process.env.REACT_APP_BASE_IMAGE_URL + res.data,
                },
            ])
            setInputsValue({ ...inputsValue, icon_path: res.data })
            setLoading('');
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    }


    const [loading, setLoading] = useState('');

    const onFinish = (values) => {
        setLoading('loading');
        put(`agent-my-account-menus/${id}`, { ...values, 'icon': inputsValue.icon_path })
            .then((res) => {
                notification.success({ message: "Modified successfully" });
                setLoading('');
                navigate("/agent-my-account-menus");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/agent-my-account-menus">
                    <ArrowLeftOutlined />
                </Link>
                My Account Menu
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        name_ar: inputsValue.name_ar,
                        name_en: inputsValue.name_en,
                        order: inputsValue.order,
                        is_active: inputsValue.is_active,
                        url: inputsValue.url,
                    }}
                >
                    <div className="row">
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Name Ar"
                                name="name_ar"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Name En "
                                name="name_en"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Order "
                                name="order"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input type="number" placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        {
                            inputsValue.type == 'web_link' &&

                            <div className="col-lg-6">
                                <Form.Item
                                    label="Url "
                                    name="url"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required!",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>

                        }
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Active " name="is_active">
                                <Switch defaultChecked={inputsValue.is_active} />
                            </Form.Item>
                        </div>

                        <div className='col-lg-6'>
                            <Form.Item
                                label='Icon'
                            >
                                <Upload
                                    beforeUpload={handleBeforeUpload}
                                    listType="picture"
                                    maxCount={1}
                                    defaultFileList={fileList}
                                    onPreview={onPreview}

                                >
                                    <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                                </Upload>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading === 'loading' ? true : loading === 'uploading' ? true : false}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading === 'loading' ? "Updating..." : loading === 'uploading' ? 'Uploading' : "Update"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default EditAgentMyAccountMenu;
