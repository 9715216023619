import React, { useEffect, useState } from "react";
import { Button, Typography, Form, Select, Input, Tag, notification } from "antd";
import { del, get } from "../../api/axios";
import { Link } from "react-router-dom";
import { Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { checkPermission } from "../auth/ProtectedRoutes";
import { onChangeTableHelper } from "../helper/TableHelpers";
import TableComponent from "../helper/TableComponent";

const AgentTrainings = () => {
    const { Title } = Typography;
    const [form] = Form.useForm();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(false);

    const [total, setTotal] = useState(1);

    const columns = [
        {
            title: " Id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: "Name Ar",
            dataIndex: "name_ar",
            sorter: (a, b) => a.name_ar - b.name_ar,
        },
        {
            title: "Name En",
            dataIndex: "name_en",
            sorter: (a, b) => a.name_en - b.name_en,
        },
        {
            title: "Order",
            dataIndex: "order",
            sorter: (a, b) => a.order - b.order,
        },
        {
            title: "Active",
            render: ({ is_active }) => (
                <>
                    <Tag color={`${is_active == '1' ? "success" : "error"}`}>
                        {is_active == 1 ? 'Active' : 'Inactive'}
                    </Tag>
                </>
            ),
        },
    ];

    if (
        checkPermission("update-agent-training") ||
        checkPermission("delete-agent-training")
    ) {
        columns.push({
            title: "Actions",
            dataIndex: "id",
            render: (id) => (
                <div>
                    {checkPermission("update-agent-training") ? (
                        <Button className="btn btn-outline-warning btn-edit btn-sm p-0 me-3">
                            <Link
                                className="color-inherit text-decoration-none py-1 px-2 d-block"
                                to={`/agent-trainings/edit/${id}`}
                            >
                                <EditOutlined />
                            </Link>
                        </Button>
                    ) : (
                        <></>
                    )}
                    {checkPermission("delete-agent-training") ? (
                        <Popconfirm
                            title="Delete item"
                            description="Are you sure you want to delete this item?"
                            onConfirm={() => confirm(id)}
                            onCancel={cancel}
                            okText="OK"
                            cancelText="Cancel"
                        >
                            <Button danger className="flex-center d-inline-flex">
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    ) : (
                        <></>
                    )}
                </div>
            ),
        });
    }

    useEffect(() => {
        get("agent-trainings?limit=50&sort_by=id&order=asc&page=1").then((res) => {
            setData(res.data.data);
            setTotal(res.data.meta.total);
            setLoading(false);
        });
    }, []);

    const handleDelete = (id) => {
        del(`agent-trainings/${id}`)
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
        let dateFilter = data.filter((item) => item.id !== id);
        setData(dateFilter);
    };

    const confirm = (id) => {
        handleDelete(id);
        notification.success({ message: "It was successful" });
    };

    const cancel = (e) => {
        notification.info({ message: "The order has been cancelled" });
    };


    const [filterData, setFilterData] = useState({
        filter_key: "",
        filter_value: "",
    });

    const [sorter, setSorter] = useState({
        sort_by: "",
        order: "",
    });

    const handleFilterKey = (e) => {
        setFilterData({ ...filterData, filter_key: e });
    };

    const handleFilterVal = (e) => {
        setFilterData({ ...filterData, [e.target.id]: e.target.value });
    };

    const onFilter = (values) => {
        setLoadingSearch(true);


        get(
            `agent-trainings?filter_key=${filterData.filter_key}&filter_value=${filterData.filter_value
            }${sorter.sort_by !== "" && sorter.sort_by !== undefined ? `&sort_by=${sorter.sort_by}` : ""}${sorter.order !== "" ? `&order=${sorter.order}` : ""
            }`
        )
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.meta.total);
                setLoadingSearch(false);
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoadingSearch(false);
            });
    };

    const clearSearch = () => {
        form.resetFields();
        setFilterData({
            filter_key: "",
            filter_value: "",
        });
        get("agent-trainings").then((res) => {
            setData(res.data.data);
            setTotal(res.data.meta.total);
        });
    };

    const onChangeTable = (pagination, filters, sorter) => {
        setLoading(true);
        setSorter({
            ...sorter,
            sort_by: sorter.field,
            order: sorter.order === "descend" ? "desc" : "asc",
        });

        onChangeTableHelper('agent-trainings', pagination, filterData, sorter)
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.meta.total);
                setLoading(false);
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-6">
                            <Title level={2} className="text-main mb-2">
                                Agent Trainings
                            </Title>
                        </div>
                        {checkPermission("create-agent-training") ? (
                            <div className="col-lg-3 offset-lg-3 text-end">
                                <Button type="primary" className="p-0" size="large">
                                    <Link
                                        className="text-decoration-none h-100 flex-center py-2 px-4"
                                        to="/agent-trainings/add"
                                    >
                                        Add Agent Training
                                    </Link>
                                </Button>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>

            <div className="grid-margin stretch-card wrapper-content">
                <Form onFinish={onFilter} layout="vertical" form={form}>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <Form.Item
                                label="Search By"
                                name="filter_key"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Select
                                    className="w-100"
                                    showSearch
                                    placeholder="select test "
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    onChange={handleFilterKey}
                                    options={[
                                        {
                                            value: "name_ar",
                                            label: "Name Ar",
                                        },
                                        {
                                            value: "name_en",
                                            label: "Name En",
                                        },
                                        {
                                            value: "id",
                                            label: "Id",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Form.Item
                                label="Search"
                                name="filter_value"
                                onChange={handleFilterVal}
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type to search..." />
                            </Form.Item>
                        </div>
                        <div className="col-12 col-lg-2 d-flex align-items-end">
                            <Form.Item className="w-100">
                                <div className="row">
                                    <div className="col-8">
                                        <Button
                                            block
                                            loading={loadingSearch}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {loadingSearch ? "Searching" : "Search"}
                                        </Button>
                                    </div>
                                    <div className="col-4">
                                        <Button
                                            onClick={clearSearch}
                                            className="flex-center"
                                            danger
                                            type="primary"
                                        >
                                            <DeleteOutlined />
                                        </Button>
                                    </div>
                                </div>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
                <TableComponent onChangeTable={onChangeTable} loading={loading} columns={columns} data={data} total={total} />
            </div>
        </div>
    );
};

export default AgentTrainings;
