import React, { useEffect, useState } from "react";
import { Input, Typography, Button, Form, Switch, notification, Select } from "antd";
import { get, post } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const AddUnitType = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({
        is_active: true,
        is_paid: true,
        is_soon: true,
        is_estimated: true,
    });

    const handleSwitch = (status, name) => {
         setInputsValue({ ...inputsValue, [name]: status });
    };

    const [dataPackages, setDataPackages] = useState([]);
    const [optionPackages, setOptionPackages] = useState([]);

    useEffect(() => {
        setOptionPackages(
            dataPackages.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataPackages]);

    const [dataUnitType, setDataUnitType] = useState([]);
    const [optionUnitType, setOptionUnitType] = useState([]);

    useEffect(() => {
        setOptionUnitType(
            dataUnitType.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataUnitType]);

    useEffect(() => {
        get("get-all-packages").then((res) => {
            setDataPackages(res.data);
        });
        get("get-all-unit-types").then((res) => {
            setDataUnitType(res.data);
        });
    }, []);

    const onFinish = (values) => {
        setLoading(true);
        

        post("unit-types", {
            ...values,
            ...inputsValue,
        })
            .then((res) => {
                notification.success({message:"Added successfully"});
                setLoading(false);
                navigate("/unit-types");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/unit-types">
                    <ArrowLeftOutlined />
                </Link>
                Add Unit Type
            </Title>
            <Form
                className="wrapper-content"
                // layout='vertical'
                onFinish={onFinish}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 15,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <div className="row">
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Name Ar"
                            name="name_ar"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Name En "
                            name="name_en"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Order "
                            name="order"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input type="number" placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Subscription"
                            name="package_ids"
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                className="w-100"
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                placeholder="Select"
                                options={optionPackages}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Parent Unit Type"
                            name="parent_id"
                        >
                            <Select
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                className="w-100"
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                placeholder="Select"
                                options={optionUnitType}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Paid " name="is_paid">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_paid")}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Soon " name="is_soon">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_soon")}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Estimated " name="is_estimated">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_estimated")}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Active " name="is_active">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_active")}
                            />
                        </Form.Item>
                    </div>
                    
                </div>

                <div className="row">
                    <div className="col-lg-4 offset-lg-4">
                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading}
                                size="large"
                                block
                                type="primary"
                                htmlType="submit"
                            >
                                {loading ? "Saving..." : "Save"}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default AddUnitType;
