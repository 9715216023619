import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Form,
  Switch,
  notification,
  Select,
} from "antd";
import { get, post } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

const { Title } = Typography;


const AddCampaign = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [inputsValue, setInputsValue] = useState({
  });

  const [dataCampaign, setDataCampaign] = useState([]);
  const [optionCampaign, setOptionCampaign] = useState([]);

  useEffect(() => {
    setOptionCampaign(
      dataCampaign.map((d) => ({
        value: d.id,
        label: d.cost,
      }))
    );
  }, [dataCampaign]);

  const [dataAgent, setDatAgent] = useState([]);
  const [optionAgent, setOptionAgent] = useState([]);

  useEffect(() => {
    setOptionAgent(
      dataAgent.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    );
  }, [dataAgent]);

  const [dataProject, setDatProject] = useState([]);
  const [optionProject, setOptionProject] = useState([]);

  useEffect(() => {
    setOptionProject(
      dataProject.map((d) => ({
        value: d.id,
        label: d.name_en,
      }))
    );
  }, [dataProject]);
  const [dataunitUnit, setDatunitUnit] = useState([]);
  const [optionunitUnit, setOptionunitUnit] = useState([]);

  useEffect(() => {
    setOptionunitUnit(
      dataunitUnit.map((d) => ({
        value: d.id,
        label: d.id,
      }))
    );
  }, [dataunitUnit]);

  useEffect(() => {
    get("get-campaign-packages").then((res) => {
      setDataCampaign(res.data);
    });
    get("get-agent-users").then((res) => {
      setDatAgent(res.data);
    });
    get("get-agent-projects").then((res) => {
      setDatProject(res.data);
    });

  }, []);

  const handleProjectId = value => {
    form.setFieldsValue({
      unit_id: '',
    });
    get(`get-agent-projects/${value}/units`).then(response => {
      setDatunitUnit(response.data);
    });

  }




  const onFinish = values => {
    setLoading(true);

    post("campaigns", {
      ...values,
      ...inputsValue,
      "status_id": 20,
    })
      .then(res => {
        notification.success({ message: "Added successfully" });
        setLoading(false);
        navigate("/campaigns");
      })
      .catch(err => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/campaigns">
          <ArrowLeftOutlined />
        </Link>
        Add Campaign
      </Title>
      <Form
        form={form}
        className="wrapper-content"
        onFinish={onFinish}
        labelCol={{
          span: 9,
        }}
        wrapperCol={{
          span: 14,
        }}

      >
        <div className="row">
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Campaign Package"
              name="campaign_package_id"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                className="w-100"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select"
                options={optionCampaign}
              />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Agent"
              name="agent_id"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                className="w-100"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select"
                options={optionAgent}
              />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Project"
              name="project_id"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                className="w-100"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select"
                options={optionProject}
                onChange={handleProjectId}
              />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Unit"
              name="unit_id"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                className="w-100"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select"
                options={optionunitUnit}
              />
            </Form.Item>
          </div>

          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Utm Source"
              name="utm_source"
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Utm Medium"
              name="utm_medium"
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Utm Campaign"
              name="utm_campaign"
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Utm Content"
              name="utm_content"
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 offset-lg-4">
            <Form.Item
              wrapperCol={{
                offset: 1,
                span: 16,
              }}
            >
              <Button
                loading={loading}
                size="large"
                block
                type="primary"
                htmlType="submit"
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddCampaign;
